import logo from './logo.svg';
import './App.css';


import { Provider } from 'react-redux';
import createStore from './reducers/store'
import StartPage from './components/StartPage';
import {
  BrowserRouter as Router,
  Link,
  useLocation
} from "react-router-dom";

import { TestComponent } from './components/TestComponent';
import ReactGA from 'react-ga'


const store = createStore()

function App(props) {
  ReactGA.initialize('UA-236026357-1')
  ReactGA.pageview(window.location.pathname + window.location.search)


  return (
    <Provider store={store}>
      <Router>
        <StartPage />
      </Router>

    </Provider>
  );
}

export default App
