import React, { useEffect, useState } from 'react'

import axios from 'axios'
import './ticker.css'
import styled from 'styled-components'
import { useTable, useSortBy } from "react-table";
import {
  useLocation
} from "react-router-dom";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}
const siteNameMap = {
  'stock': 'stockpokeronline.com',
  'rounder': 'roundercasino.com'
}

function Table({ columns, data }) {
  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow
  } = useTable(
    {
      columns,
      data
    },
    useSortBy
  );


  return (
    <>
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                // Add the sorting props to control sorting. For this example
                // we can add them into the header props
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render("Header")}
                  {/* Add a sort direction indicator */}
                  <span>
                    {column.isSorted
                      ? column.isSortedDesc
                        ? " 🔽"
                        : " 🔼"
                      : ""}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      <br />
    </>
  );
}


async function makeAPICall(uid, tState, site, allHud, playersToFetch) {
  console.log("allhud is " + allHud)

  if (uid && tState) {


    if (tState === 'registering') {
      const uri = `https://api.cornblaster.com/pokerdata/running/${uid}`
      const response = await axios.get(uri)
      if (response.data !== null) {
        return response.data
      }
    }

    const uri = `https://api.cornblaster.com/pokerdata/${tState}/${uid}`
    const response = await axios.get(uri)
    if (response?.data?.players) {

      const conditionalFilter = (players) => {
        const filtered = players.filter(p => p?.chips)
        if (filtered.length > 0) return filtered
        return players
      }
      const playerNames = !playersToFetch ? conditionalFilter(response.data.players).map(p => p.playerName).join(',') : playersToFetch


      const playerData = {}
      response.data.players.forEach(player => {
        playerData[player.playerName] = player
      })

      const tournamentName = response.data.tournamentName
      const numPlayers = playerNames.split(',').length
      const tournamentId = response.data.tournamentId

      const siteName = siteNameMap[site]


      let hudUrl = `https://api.cornblaster.com/pokerdata/${siteName}/tournament_hud_stats/${tournamentId}/${playerNames}`
      if (allHud) {
        console.log("all hud is true")
        hudUrl = `https://api.cornblaster.com/pokerdata/${siteName}/hud_stats/${playerNames}?tournament_id=${tournamentId}`
      }


      const hudResponse = await axios.get(hudUrl)
      const hudData = hudResponse.data.hud_data.map(hud => {
        return { ...hud, ...playerData[hud.player_name] }
      })



      if (hudResponse.data !== null) {

        return { hud_data: hudData, tData: { tournamentName, numPlayers, tournamentId } }
      }

    }
    return response.data


  }

}




function HudView(props) {
  const { uid, tstate, siteName, allHud, tableId } = props
  const [hudData, setHudData] = useState("")
  const [allHudData, setAllHudData] = useState("")
  const [tournamentName, setTournamentName] = useState("Loading... Please wait.")
  const [numPlayers, setNumPlayers] = useState(0)
  const [tournamentId, setTournamentId] = useState("99999999")
  const [secretAllHud, setSecretAllHud] = useState()
  const [playersToFetch, setPlayersToFetch] = useState('')

  console.log("useLocation()")
  const location = useLocation()
  console.log(location)

  const handleClick = event => {
    // 👇️ refers to the div element
    if(event.detail === 3 && tableId) {
        setSecretAllHud(true)
        console.log('secret hud activated')
    }

  };

  const renderTableIdLink = (tableId) => {
    let path = location.pathname + location.search + '&tableId=' + tableId
    let element = <a href={path}>{tableId}</a>
    return element

  }



  const columns = [
    {
      Header: `#${tournamentId} - ${tournamentName} - ${numPlayers} players`,
      columns: [
        {
          Header: "Table ID",
          accessor: 'table_id'
        },
        {
          Header: "Position",
          accessor: 'position'
        },
        {
          Header: "Player",
          accessor: "player_name"
        },
        {
          Header: "# Rebuys",
          accessor: 'numRebuys'
        },
        {
          Header: "# Hands",
          accessor: "total_hands"
        },
        {
          Header: "VPIP %",
          accessor: "VPIP"
        },
        {
          Header: "Preflop Raise %",
          accessor: "PFR"
        },
        {
          Header: "3-Bet %",
          accessor: "3BET"
        },
        {
          Header: "Went To Showdown %",
          accessor: "WTSD"
        },
        {
          Header: "Won at Showdown %",
          accessor: "WSD"
        },
        {
          Header: "WWSF %",
          accessor: "WWSF"
        },
        {
          Header: "Aggression",
          accessor: 'AG'
        }
      ]
    }
  ]



  useEffect(() => {


    async function fetchData(uid, tState, getAllHud) {
      const apiData = await makeAPICall(uid, tState, siteName, getAllHud, playersToFetch)


      if (getAllHud) {
        setAllHudData(apiData?.hud_data || [])
      }
      else {
        setHudData(apiData?.hud_data || [])
        if(tableId) {
          console.log("filter out the players from hud data")
          const players = apiData?.hud_data.filter(player => player.table_id === tableId).map(player => player.player_name).join(',')

          console.log("got players string here:")
          console.log(players)
          setPlayersToFetch(players)


        }
      }
      setTournamentId(apiData?.tData?.tournamentId)
      setTournamentName(apiData?.tData?.tournamentName)
      setNumPlayers(apiData?.tData?.numPlayers)

    }

    fetchData(uid,tstate,false)
    if (allHud || secretAllHud) {
      fetchData(uid, tstate, true)
    }


    const interval = setInterval(() => {
      fetchData(uid,tstate,false)
      if (allHud) {
        fetchData(uid, tstate, true)
      }

    }, 10000)


    return () => {
      return clearInterval(interval)

    }

  }, [uid, tstate, siteName, allHud, secretAllHud])

  const tableFilter = (hudData) => {
    const filtered = hudData.filter(hud => hud.table_id == tableId)
    if (filtered.length > 0) return filtered
    return hudData

  }

  let displayData
  let hudTable = null
  let allHudTable = null
  if (!hudData) {
    hudTable = <p>Loading, please wait...</p>
  }
  else {
    const newHudData = tableFilter(hudData).map((hud) => {
      return { ...hud, table_id: renderTableIdLink(hud['table_id']) }
    });
    hudTable = <Table columns={columns} data={newHudData} />
  }

  if (!allHudData && (allHud || secretAllHud)) {
    allHudTable = <p>Loading, please wait...</p>
  }
  else if(allHudData && (allHud || secretAllHud)) {
    const newHudData = tableFilter(allHudData).map((hud) => {
      return { ...hud, table_id: renderTableIdLink(hud['table_id']) }
    });
    allHudTable = <Table columns={columns} data={newHudData} />
  }




  displayData = <Styles>{hudTable}{allHudTable}</Styles>

  return (
    <div onClick={handleClick}>{displayData}</div>
  )



}

const Styles = styled.div`
  padding: 1rem;

  table {
    border-spacing: 0;
    border: 1px solid black;

    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    thead tr:first-of-type {
      background-color:rgba(81, 81, 81, 1);
      color: white;
    }
    thead tr:nth-child(2) {
      background-color:rgb(151, 151, 151);
      color:white;
      border-bottom:5px solid black;
    }

    th,
    td {
      margin: 0;
      padding: 0.5rem;
      border-bottom: 1px solid gray;
      border-right: 1px solid gray;

      :last-child {
        border-right: 0;
      }
    }
  }
`


export default HudView