import React, { useEffect, useState } from 'react'

import axios from 'axios'
import './ticker.css'
import styled from 'styled-components'
import { useTable, useSortBy } from "react-table";



const siteNameMap = {
  'stock': 'stockpokeronline.com',
  'rounder': 'roundercasino.com'
}

function Table({ columns, data }) {
  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow
  } = useTable(
    {
      columns,
      data
    },
    useSortBy
  );


  return (
    <>
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                // Add the sorting props to control sorting. For this example
                // we can add them into the header props
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render("Header")}
                  {/* Add a sort direction indicator */}
                  <span>
                    {column.isSorted
                      ? column.isSortedDesc
                        ? " 🔽"
                        : " 🔼"
                      : ""}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      <br />
    </>
  );
}


async function makeAPICall(siteName, playerNames) {

      const hudUrl = `https://api.cornblaster.com/pokerdata/${siteName}/hud_stats/${playerNames.join(',')}`
      const hudResponse = await axios.get(hudUrl)
      if (hudResponse?.data?.hud_data) {

        return hudResponse?.data?.hud_data
      }

      else {
        return []
      }

    }




function CashHudView(props) {
  const { playerNames, siteName, tableName } = props
  const [hudData, setHudData] = useState("")


  const columns = [
      {
        Header: `${tableName} - ${playerNames.length} players`,
        columns: [
          {
            Header: "Player Name",
            accessor: "player_name"
          },
          {
            Header: "Hand Sample Size",
            accessor: "total_hands"
          },
          {
            Header: "Voluntary $ Put Into Pot %",
            accessor: "VPIP"
          },
          {
            Header: "Preflop Raise %",
            accessor: "PFR"
          },
          {
            Header: "3-Bet %",
            accessor: "3BET"
          },
          {
            Header: "Went to Showdown %",
            accessor: "WTSD"
          },
          {
            Header: "Won at Showdown %",
            accessor: "WSD"
          },
          {
            Header: "Won When Saw Flop %",
            accessor: "WWSF"
          },
          {
            Header: "Aggression Index",
            accessor: 'AG'
          }
        ]
      }
    ]



  useEffect(() => {


    async function fetchData(uid, tState) {
      const apiData = await makeAPICall(uid, tState, siteName)
      setHudData(apiData)

    }
    fetchData(siteName, playerNames)
    const interval = setInterval(() => { fetchData(siteName,playerNames) }, 60000)


    return () => {
      return clearInterval(interval)

    }

  }, [playerNames, siteName])

  let displayData
  if(!hudData) {
    displayData = <Styles>Loading, please wait...</Styles>
  }
  else {
    displayData = <Styles><Table columns={columns} data={hudData} /></Styles>
  }

  return (
    <div>{displayData}</div>
  )



}

const Styles = styled.div`
  padding: 1rem;

  table {
    border-spacing: 0;
    border: 1px solid black;

    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    thead tr:first-of-type {
      background-color:rgba(81, 81, 81, 1);
      color: white;
    }
    thead tr:nth-child(2) {
      background-color:rgb(151, 151, 151);
      color:white;
      border-bottom:5px solid black;
    }

    th,
    td {
      margin: 0;
      padding: 0.5rem;
      border-bottom: 1px solid gray;
      border-right: 1px solid gray;

      :last-child {
        border-right: 0;
      }
    }
  }
`


export default CashHudView