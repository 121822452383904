import React, {useState,useEffect} from "react";
import axios from 'axios'
import styled from 'styled-components'
import { useTable, usePagination } from 'react-table'

import { useTheme } from '@table-library/react-table-library/theme';
import {
    BrowserRouter as Router,
    useLocation
} from "react-router-dom";

import "./stats.css";

function useQuery() {
    return new URLSearchParams(useLocation().search);
}


const Styles = styled.div`
  padding: 1rem;

  table {
    border-spacing: 0;
    border: 1px solid black;
    height:100%;

    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th,
    td {
      margin: 0;
      padding: 0.5rem;
      border-bottom: 1px solid black;
      border-right: 1px solid black;

      :last-child {
        border-right: 0;
      }
    }
  }

  .pagination {
    padding: 0.5rem;
  }
`

function Table({ columns, data }) {
    // Use the state and functions returned from useTable to build your UI
    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      prepareRow,
      page, // Instead of using 'rows', we'll use page,
      // which has only the rows for the active page

      // The rest of these things are super handy, too ;)
      canPreviousPage,
      canNextPage,
      pageOptions,
      pageCount,
      gotoPage,
      nextPage,
      previousPage,
      setPageSize,
      state: { pageIndex, pageSize },
    } = useTable(
      {
        columns,
        data,
        initialState: { pageIndex: 0, pageSize:10 },
      },
      usePagination
    )


    return (
      <div>
        <table {...getTableProps()}>
          <thead>
            {headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row, i) => {
                console.log("i is " + i)

                const rowMod = i%2
                console.log("row mod is " + rowMod)
              prepareRow(row)
              return (
                <tr className={rowMod == 1 ? "odd" : "even"} {...row.getRowProps()}>
                  {row.cells.map(cell => {
                    return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                  })}
                </tr>
              )
            })}
          </tbody>
        </table>

        {/*
          Pagination can be built however you'd like.
          This is just a very basic UI implementation:
        */}
        <div className="pagination">
          <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
            {'<<'}
          </button>{' '}
          <button onClick={() => previousPage()} disabled={!canPreviousPage}>
            {'<'}
          </button>{' '}
          <button onClick={() => nextPage()} disabled={!canNextPage}>
            {'>'}
          </button>{' '}
          <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
            {'>>'}
          </button>{' '}
          <span>
            Page{' '}
            <strong>
              {pageIndex + 1} of {pageOptions.length}
            </strong>{' '}
          </span>
          <span>
            | Go to page:{' '}
            <input
              type="number"
              defaultValue={pageIndex + 1}
              onChange={e => {
                const page = e.target.value ? Number(e.target.value) - 1 : 0
                gotoPage(page)
              }}
              style={{ width: '100px' }}
            />
          </span>{' '}
          <select
            value={pageSize}
            onChange={e => {
              setPageSize(Number(e.target.value))
            }}
          >
            {[10, 20, 50, 100, 500].map(pageSize => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </select>
        </div>
      </div>
    )
  }
function StatTable() {
    const query = useQuery()
    const site = query.get('site')

    useEffect(() => {
        const getAPIData = async () => {
            const uri = `https://api.cornblaster.com/pokerdata/${site}/all`
            const response = await axios.get(uri)
            console.log(response)
            const dates = response.data.shift()
            console.log('DATES')
            console.log(dates)
            setData(response.data)


            setFirstHeader(`Tournament Stats (${dates.start} - ${dates.end})`)
        }
        getAPIData()



    },[])
    const [firstHeader, setFirstHeader] = useState('Tournament Stats')
    const columns = React.useMemo(
        () => [
            {Header: firstHeader, columns: [
                {Header: 'Rank', accessor: 'rank'},
                {Header: 'Name', accessor: 'name' },
                {Header: 'Net Winnings', accessor: 'net_winnings' },
                {Header: 'Avg Winnings', accessor: 'avg_winnings' },
                {Header: '# Entries', accessor: 'entries' },
                {Header: '# Cashed', accessor: 'wins'},
                {Header: 'Win %', accessor: 'win_pct'}
            ]},
                {Header: 'HUD Stats', columns: [
                {Header: '# Hands', accessor: 'total_hands'},
                {Header: 'VPIP', accessor: 'VPIP'},
                {Header: 'PFR', accessor: 'PFR'},
                {Header: '3-Bet', accessor: '3BET'},
                {Header: 'WTSD', accessor: 'WTSD'},
                {Header: 'WSD', accessor: 'WSD'},
                {Header: 'WWSF', accessor: 'WWSF'},
            ]}]

    )
    const [data,setData] = useState([])

    const theme = useTheme({
        Table: `
          height: 100%;
        `,
      });

    return <div style={{height: '150px'}}><Styles><Table theme={theme}  columns={columns} data={data}></Table></Styles></div>



  }
export default StatTable
