
import React, { useEffect, useState } from 'react'
import './ticker.css'


import {
    useLocation
} from "react-router-dom";

import { connect } from 'react-redux'
import ValheimDemo from './ValheimDemo'
import OverlayHome from './OverlayHome2'
import SpinningCorn from './SpinningCorn'
import SpinningAceOfCorn from './SpinningAceOfCorn'
import StandingsTicker from './StandingsTicker';
import StatTable from './StatTable'

import './start.css'
import { get_random_tournament, get_tournament_data } from '../actions/actions';
import HudStart from './HudStart';





function StartPage(props) {




    useEffect(() => {
        props.getRandomTournament()


    }, [])



    const location = useLocation()


    const path = location.pathname

    // if (path.indexOf('/aceofcorn') !== -1) {
    //     return (<SpinningAceOfCorn />)
    // }

    // if (path.indexOf('/hudstart') !== -1) {
    //     return (<HudStart></HudStart>)
    // }

    // if (path.indexOf('/stats') !== -1) {
    //     return (<StatTable></StatTable>)
    // }


    // if (path.indexOf('/corn') !== -1) {
    //     return (<SpinningCorn />)
    // }

    // if (path.indexOf('valheim') !== -1) {

    //     return (<ValheimDemo />)
    // }

    if (path.indexOf('overlays') !== -1) {
        let sitePicked = 'none'
        if (path.indexOf('stock') !== -1) sitePicked = 'stockpokeronline.com'
        else if (path.indexOf('rounder') !== -1) sitePicked = 'roundercasino.com'
        return <OverlayHome randomTournament={props.randomTournament} site={sitePicked} />

    }

    else {


        window.location.replace('https://pokerapp.danchrostowski.com')
    }
}


const mapStateToProps = state => ({
    randomTournament: state.random_tournament,
    tournamentData: state.tournament_data
})

const mapDispatchToProps = dispatch => ({
    getTournamentData: (uid, tstate) => {
        dispatch(get_tournament_data(uid, tstate))
    },
    getRandomTournament: () => {
        dispatch(get_random_tournament())
    },

})

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(StartPage)